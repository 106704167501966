import { WITHDRAW_VISIT_PAGE } from "@redux/actionTypes";
import { loadState } from "@redux/helpers/localStorage";

const persistedState = loadState() || { withdraw: {} };

const initialState = {
  id: null,
  ...persistedState.withdraw,
};

const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case WITHDRAW_VISIT_PAGE:
      return {
        ...state,
        withdraw: {
          amount: action.payload.amount,
          failure_url: action.payload.failure_url,
          success_url: action.payload.success_url,
          network: action.payload.network,
        },
      };
    default:
      return state;
  }
};

export default withdrawReducer;
