import { createStore, combineReducers } from "redux";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import paymentReducer from "./reducers/payment";
import withdrawReducer from "./reducers/withdraw";
import { saveState } from "@redux/helpers/localStorage";
import debounce from "debounce";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  payment: paymentReducer,
  withdraw: withdrawReducer,
});

const devTools = () => {
  if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    return window.__REDUX_DEVTOOLS_EXTENSION__();
  }

  return undefined;
};

const store = createStore(rootReducer, devTools());
store.subscribe(
  debounce(() => {
    saveState({ ...store.getState() });
  }, 1000)
);

export default store;
