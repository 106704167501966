import React from "react";
import { Provider } from "react-redux";
import store from "@redux/store";
import { appWithTranslation } from "next-i18next";

import Layout from "@components/Layout";
import "@styles/globals.css";

function Partner({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Provider>
  );
}

export default appWithTranslation(Partner);
