import { AUTH_LOGIN_SUCCESS } from '@redux/actionTypes'
import { loadState } from '@redux/helpers/localStorage'

const persistedState = loadState() || { auth: {} }

const initialState = {
  loggedIn: false,
  accessToken: null,
  ...(persistedState.auth),
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        accessToken: action.payload.accessToken
      }
    default:
      return state
  }
}

export default authReducer


