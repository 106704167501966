import {
  PAYMENT_VISIT_PAGE,
  PAYMENT_PERFORM_SUCCESS,
  PAYMENT_PERFORM_FAILURE,
  PAYMENT_PERFORM_COMPLETED,
  PAYMENT_SET_FIELDS,
} from "@redux/actionTypes";
import { loadState } from "@redux/helpers/localStorage";

const persistedState = loadState() || { payment: {} };

const initialState = {
  id: null,
  ...persistedState.payment,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_VISIT_PAGE:
      return {
        ...state,
        id: action.payload.id,
        successUrl: action.payload.successUrl,
        failureUrl: action.payload.failureUrl,
        minAmount: action.payload.minAmount,
        amount: action.payload.amount,
        network: action.payload.network,
        walletAddress: action.payload.walletAddress,
        type: action.payload.type,
        status: action.payload.status,
      };
    case PAYMENT_PERFORM_SUCCESS:
      return {
        ...state,
        selectedBank: action.payload.selectedBank,
        depositCode: action.payload.depositCode,
      };
    case PAYMENT_PERFORM_FAILURE:
      return {
        ...state,
        reason: action.payload.reason,
      };
    case PAYMENT_PERFORM_COMPLETED:
      return {
        ...state,
        timestamp: action.payload.timestamp,
      };
    case PAYMENT_SET_FIELDS:
      return {
        ...state,
        selectedAmount: action.payload.selectedAmount,
      };
    default:
      return state;
  }
};

export default paymentReducer;
