import { USER_KYC1_SUCCESS, USER_FETCH_CURRENT } from '@redux/actionTypes'
import { loadState } from '@redux/helpers/localStorage'

const persistedState = loadState() || { user: {} }

const initialState = {
  id: null,
  name: '',
  email: '',
  id1_status: '',
  ...(persistedState.user),
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_CURRENT:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        id1_status: action.payload.id1_status
      }
    case USER_KYC1_SUCCESS:
      return {
        ...state,
        id1_status: action.payload.id1_status
      }
    default:
      return state
  }
}

export default userReducer
