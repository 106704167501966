import Head from "next/head";
import Image from "next/image";
import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Link from "next/link";

export default function Header() {
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
    <>
      <Head>
        <title>BiLira - Ortak Ödeme Sayfası</title>
        <meta name="description" content="BiLira iş ortakları için ortak ödeme sayfası" />
        <link rel="icon" href="/favicon.png" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200..900&display=swap" rel="stylesheet" />
      </Head>
      <header className="flex text-gray-600 body-font justify-between">
        <div className="container p-5">
          <Image src="/logo.svg" alt="logo" width={120} height={42} className="object-cover object-center" />
        </div>
        <div className="container p-5 pr-10 text-right">
          <Link href={router.asPath} locale={router.locale === "en" ? "tr" : "en"}>
            <button style={{ padding: "-30px" }} type="button" className="text-sm font-medium text-primary-dark">
              <div className={"flex items-center"}>
                <span>
                  <Image width={15} height={15} src={"/assets/img/global.svg"} />
                </span>
                <span className={"pl-1 -mt-1 self-center"}> {t("change-locale")}</span>
              </div>
            </button>
          </Link>
        </div>
      </header>
    </>
  );
}
