export function loadState() {
  try {
    const serializedState = sessionStorage.getItem("REDUXSTORAGE");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}
/**
 * Saves given state to localStorage by key.
 *
 * @param {T} state - A state to be stored in localStorage
 */
export function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("REDUXSTORAGE", serializedState);
  } catch (err) {
    // TODO: Error handler later.
    console.error("err", err);
  }
}

export function resetState() {
  try {
    sessionStorage.removeItem("REDUXSTORAGE");
  } catch (err) {
    // TODO: Error handler later.
    console.error("err", err);
  }
}
