import React from "react";
/* eslint-disable max-len */
export default function Footer() {
  return (
    <footer className="footer">
      <p className="text-gray-500 text-sm text-center sm:text-left">
        © 2021{" "}
        <a href="https://bilira.co" rel="noopener noreferrer" className="text-gray-600" target="_blank">
          BiLira
        </a>
      </p>
    </footer>
  );
}
