// Naming convention: SCOPE_ACTION_TYPE

export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const USER_FETCH_CURRENT = "USER_FETCH_CURRENT";
export const USER_KYC1_SUCCESS = "USER_KYC1_SUCCESS";
export const PAYMENT_VISIT_PAGE = "PAYMENT_VISIT_PAGE";
export const PAYMENT_SET_FIELDS = "PAYMENT_SET_FIELDS";
export const PAYMENT_PERFORM_SUCCESS = "PAYMENT_PERFORM_SUCCESS";
export const PAYMENT_PERFORM_FAILURE = "PAYMENT_PERFORM_FAILURE";
export const PAYMENT_PERFORM_COMPLETED = "PAYMENT_PERFORM_COMPLETED";

export const WITHDRAW_VISIT_PAGE = "WITHDRAW_VISIT_PAGE";
